import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { 
  TbAugmentedReality,
  TbBrandApple,
} from "react-icons/tb";
import {
  MdArrowBack,
  MdOutlineCode,
} from "react-icons/md";
import { BsGithub } from "react-icons/bs";
import { 
  SiUnity,
  SiCsharp,
  SiFigma,
  SiXcode,
  SiAdobephotoshop,
} from "react-icons/si";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import charmVideo from "../../Assets/Projects/charmvideo.mp4";

function Projects() {
  return (
    <Container fluid className="project-section-invidual">

      {/* Banner */}
      <Container fluid style={{paddingLeft: 20, paddingRight: 20}}>
        <Row className="portolioBannerCharm">
          <a href="/project" className="backArrow">
            <MdArrowBack className="backArrowIcon"/>
          </a>
          <h1 className="portolioHeader">
            <strong>CHARM </strong>
          </h1>
          <p className="portfolioSubtext">
            <TbAugmentedReality/> AR Development | <MdOutlineCode/> Backend Development | <TbBrandApple/> iOS Development
          </p>
          <div className="d-flex justify-content-center">
                <a className="button-17" target="_blank" rel="noopener noreferrer" role="button" href={"https://github.com/Robin-Boe/MIXMAGICAR"}> <BsGithub size={23}/> &nbsp;<b> View Prototype</b></a>
          </div>
        </Row>
      </Container>

      {/* Progress Bar */}
      <Container className="bar">
        <Row style={{paddingTop: 40}}>
          {/* Link for Pokemon Icons https://www.pokencyclopedia.info/en/index.php?id=sprites/spin-off/ico_shuffle */}
          <ProgressBar position percent={100} filledBackground="linear-gradient(to right, #C889E6, #d345af)">
            <Step transition="scale">
              {({ accomplished }) => (
                <a className="progressBarIcon" href={"#about"}><div className={`transitionStep ${accomplished ? "accomplished" : null}`}>
                  <img style={{ paddingTop: 30}} width="30" alt="step1" src="https://www.pokencyclopedia.info/sprites/spin-off/ico_shuffle/ico_shuffle_636.png"/>
                  <p style={{fontFamily: "Lato", fontWeight: 600}}> Overview </p>
                </div></a>
              )}
            </Step>
            <Step transition="scale">
              {({ accomplished }) => (
                <a className="progressBarIcon" href={"#prototype"}><div className={`transitionStep ${accomplished ? "accomplished" : null}`}>
                <img style={{ paddingTop: 30}} width="30" alt="step3" src="https://www.pokencyclopedia.info/sprites/spin-off/ico_shuffle/ico_shuffle_637.png"/>
                <p style={{fontFamily: "Lato", fontWeight: 600}}> Prototype </p>
              </div></a>
              )}
            </Step>
          </ProgressBar>
        </Row>
      </Container>

      {/* Overview Text */}
      <Container style={{paddingTop: 60, paddingBottom: 30}}>
        <Row>
          <Col md={7}>
            <h1 className="blogHeader" id="about"> Project Overview </h1>
            <p className="blogText"> 
            Charm allows you to feel magic through your veins, but displaying magical particles appearing from your hands. 
            Feel like a real wizard by creating particles from your hand, or make a floating orb appear with Charm. 
            Charm is an augmented reality (AR) app for iOS (Apple) devices, which tracks and provides feedback depending on the position of your hand. 
            </p>
          </Col>
          <Col md={5}>
            <h1 className="blogSubheader"> Components Used/Created </h1>
            <p className="blogComponents"> 
              <SiUnity/> &nbsp; Unity <br></br>
              <SiCsharp/> &nbsp; C# Scripts <br></br>
              <SiFigma/> &nbsp; Figma <br></br>
              <SiAdobephotoshop/> &nbsp; Photoshop <br></br>
              <BsGithub/> &nbsp; Github <br></br>
              <SiXcode /> &nbsp; XCode <br></br>
            </p>
          </Col>
        </Row>

        {/* Details About the Project */}
        <Row>
          <Col md={7}>
            <h1 className="blogSubheader"> Project Details </h1>
            <p className="blogText"> 
            The project involved attempting to create a prototype from completely new technology (i.e., no experience beforehand). 
            Charm was developed in a group of 4, where designing, planning and discussion were performed with all members. 
            However, the prototype itself was developed by myself. The project involved methods such as design sprints, MVP (minimum viable product), 
            storyboarding for ideation. For instance, discussing which types of interactions were viable and useful to include. Such as, 
            how the player should trigger specific effects, for instance through movement or signs performed in the hand. 
            The prototype itself was developed through Unity with C# scripts and ported to iOS devices with the help of Xcode. 
            </p>
          </Col>
        </Row>  

        {/* Prototype Text */}
        <Row>
          <Col md={10}>
            <h1 className="blogHeader" id="prototype"> Prototype </h1>
            <p className="blogText"> 
            The prototype itself is fairly simple, and includes a limited amount of actions. The player can move their hand up/down to trigger specific effects. 
            For instance, if the player moves their hand above their head, they will trigger a particle effect in their hand. The player can also summon an orb 
            (which is a placeholder cube) if they hold their hand at stomach level. This orb will change color when turning it from the left to the right. 
            You can view the project's code on &nbsp;
            <a className="linkInText" target="_blank" rel="noopener noreferrer" href="https://github.com/Robin-Boe/MIXMAGICAR">GitHub!</a>
            &nbsp;Also, view the video below for a showcase of the prototype!
            </p>
          </Col>

          {/* Video */}
          <video style={{height: 700, width: 500, paddingBottom: 60}} controls loop src={charmVideo} type="video/mp4"></video>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { 
  FaVrCardboard,
  FaGamepad,  
} from "react-icons/fa";
import {
  MdArrowBack,
  MdOutlineViewQuilt,
  MdOutlineCode,
} from "react-icons/md";
import { BsGithub } from "react-icons/bs";
import { 
  SiUnity,
  SiCsharp,
  SiFigma,
  SiAdobephotoshop,
  SiAdobe,
  SiMeta,   
  SiDropbox ,
  SiYoutube ,
} from "react-icons/si";
import { HiGlobeAlt } from "react-icons/hi";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import agile from "../../Assets/Projects/agileCycle.png";

function Projects() {
  return (
    <Container fluid className="project-section-invidual">

      {/* Banner */}
      <Container fluid style={{paddingLeft: 20, paddingRight: 20}}>
        <Row className="portolioBannerHauntedLegacy">
          <a href="/project" className="backArrow">
            <MdArrowBack className="backArrowIcon"/>
          </a>
          <h1 className="portolioHeader">
            <strong>Haunted Legacy </strong>
          </h1>
          <p className="portfolioSubtext">
            <FaGamepad/> Game Design | <FaVrCardboard/> VR Development | <MdOutlineViewQuilt/> UX/UI Design | <MdOutlineCode/> Backend Development
          </p>
          <div className="d-flex justify-content-center">
                <a className="button-17" target="_blank" rel="noopener noreferrer" role="button" href={"https://robin-boe.github.io/HauntedLegacy/"}> <BsGithub size={23}/> &nbsp;<b> View Prototype</b></a>
          </div>
        </Row>
      </Container>

      {/* Progress Bar */}
      <Container className="bar">
        <Row style={{paddingTop: 40}}>
          {/* Link for Pokemon Icons https://www.pokencyclopedia.info/en/index.php?id=sprites/spin-off/ico_shuffle */}
          <ProgressBar position percent={100} filledBackground="linear-gradient(to right, #C889E6, #d345af)">
            <Step transition="scale">
              {({ accomplished }) => (
                <a className="progressBarIcon" href={"#about"}><div className={`transitionStep ${accomplished ? "accomplished" : null}`}>
                  <img style={{ paddingTop: 30}} width="30" alt="step1" src="https://www.pokencyclopedia.info/sprites/spin-off/ico_shuffle/ico_shuffle_406.png"/>
                  <p style={{fontFamily: "Lato", fontWeight: 600}}> Overview </p>
                </div></a>
              )}
            </Step>
            <Step transition="scale">
              {({ accomplished }) => (
                <a className="progressBarIcon" href={"#development"}><div className={`transitionStep ${accomplished ? "accomplished" : null}`}>
                <img style={{ paddingTop: 30}} width="30" alt="step2" src="https://www.pokencyclopedia.info/sprites/spin-off/ico_shuffle/ico_shuffle_315.png"/>
                <p style={{fontFamily: "Lato", fontWeight: 600}}> Development </p>
              </div></a>
              )}
            </Step>
            <Step transition="scale">
              {({ accomplished }) => (
                <a className="progressBarIcon" href={"#prototype"}><div className={`transitionStep ${accomplished ? "accomplished" : null}`}>
                <img style={{ paddingTop: 30}} width="30" alt="step3" src="https://www.pokencyclopedia.info/sprites/spin-off/ico_shuffle/ico_shuffle_407.png"/>
                <p style={{fontFamily: "Lato", fontWeight: 600}}> Prototype </p>
              </div></a>
              )}
            </Step>
          </ProgressBar>
        </Row>
      </Container>

      {/* Overview Text */}
      <Container style={{paddingTop: 60, paddingBottom: 30}}>
        <Row>
          <Col md={7}>
            <h1 className="blogHeader" id="about"> Project Overview </h1>
            <p className="blogText"> 
            Haunted Legacy is a virtual reality horror-adventure game for the Meta Quest headsets. 
            The game is a 10 - 20 long minute game depicting a detective investigating an abandoned mansion for a set of valuable paintings. 
            The game includes an in depth interface, including a tutorial, pause menu and visual salience 
            (i.e. light which guides the player in finding important objects). Furthermore, the game boasts 
            several endings (total of 4), which are triggered depending on the progress the player has in their collection of the paintings.
            Items, including the paintings, are spawned randomly each time the player starts the game. 
            Monsters come and hunt the player when they progress. These monsters are also randomly spawned.  
            </p>
          </Col>
          <Col md={5}>
            <h1 className="blogSubheader"> Components Used/Created </h1>
            <p className="blogComponents"> 
              <SiUnity/> &nbsp; Unity <br></br>
              <SiCsharp/> &nbsp; C# Scripts <br></br>
              <SiFigma/> &nbsp; Figma <br></br>
              <SiAdobephotoshop/> &nbsp; Photoshop <br></br>
              <SiAdobe/> &nbsp; Mixamo<br></br>
              <BsGithub/> &nbsp; Github <br></br>
              <SiMeta/> &nbsp; Meta Quest Developer Hub <br></br>
            </p>
          </Col>
        </Row>

        {/* Project Details */}
        <Row>
          <Col md={7}>
            <h1 className="blogSubheader"> Project Details </h1>
            <p className="blogText"> 
              The project was created as part of the Master Thesis in Media- and interaction design. It was co-created by a fellow student and I.
              Both performed scripting of the game, however, we both had our focus. My focus was on the interface, whereas my fellow student worked on 
              audio hallucination (i.e., audio which appears at random moments to provoke a reaction from the player). 
              As a result, all the interfaces were created by myself.  
            </p>
          </Col>
        </Row>
         

        {/* Development Text */}
        <Row>
          <Col md={7}>
            <h1 className="blogHeader" id="development"> Development </h1>
            <p className="blogText"> 
            The core of the development revolved around agile development, following the pattern of:<br></br> <br></br>

              1) Design / Modify elements<br></br>
              2) Implement Design<br></br>
              3) Playtest<br></br>
              4) Evaluate<br></br><br></br>

            This process was performed following weekly Scrum sprints, where we had designated tasks for each week. 
            Each sprint concluded with a sprint retrospective where the design was evaluated, and redesigned if necessary. 
            All the interface in the game was designed first in Figma/Photoshop, and later implemented into the game. 
            Some were also redesigned when playtests and sprint retrospects found they were not up to par. 
            The following &nbsp;
            <a target="_blank" rel="noopener noreferrer" className="linkInText" href="https://drive.google.com/drive/folders/1Hn6UMJ-AT6abxYJUM35XOPM-52VPjiqA?usp=sharing">link</a>
            &nbsp;is a Google Drive folder with all the interfaces, including PSD files. 
            </p>
          </Col>
          <Col md={5}>
            <img className="agilePicture" alt="persona1" src={agile} style={{paddingTop: 30, borderRadius: 40}}></img>
            <p className="figureText"> Agile Development Process Followed </p>
          </Col>
        </Row>
        
        <Row>
          <Col md={7}>
            <h1 className="blogSubheader" id="development"> Testing </h1>
            <p className="blogText"> 
              During the development of the prototype, the game was tested by ourselves. However, we recruited independent participants 
              (who had no experience with the prototype beforehand), when the game had reached a point where it would be fruitful to test with users. 
              Participants were recruited through social media, forums and locally at the University campus. In total 12 participants playtested the game and provided data. 
              The following methods were used to collect data:<br></br> <br></br>

                1) Observations with VR playtest with Meta Quest headset<br></br>
                2) Health tracking (e.g., BPM)<br></br>
                3) Close-ended questionnaires<br></br>
                4) Interviews<br></br><br></br>

              The data was analyzed through various methods, and the game evolved from the data collected. 
              If you're interested in inquiring more about the details of the playtesting, take a look at my&nbsp;
              <a target="_blank" rel="noopener noreferrer" className="linkInText" href="https://bora.uib.no/bora-xmlui/handle/11250/3141355">Master's Thesis.</a>
            </p>
          </Col>
        </Row>

        {/* Prototype Text */}
        <Row>
          <Col md={7}>
            <h1 className="blogHeader" id="prototype"> Prototype </h1>
            <p className="blogText"> 
              The prototype is gone through in extensive detail on the game's website. Additionally, you can find all the code, 
              the game itself and a walkthrough video of the entire game through the following links: <br></br> <br></br>

              <a target="_blank" rel="noopener noreferrer" className="hauntedLegacyIcons" href="https://robin-boe.github.io/HauntedLegacy/"> <HiGlobeAlt/> Game's Website </a><br></br>
              <a target="_blank" rel="noopener noreferrer" className="hauntedLegacyIcons" href="https://github.com/Robin-Boe/HauntedLegacyVR"> <BsGithub/> View the Code </a><br></br>
              <a target="_blank" rel="noopener noreferrer" className="hauntedLegacyIcons" href="https://www.dropbox.com/home/HauntedLegacy"> <SiDropbox/> Download the Game </a><br></br>
              <a target="_blank" rel="noopener noreferrer" className="hauntedLegacyIcons" href="https://youtu.be/td50R99cFgg"> <SiYoutube  /> Walkthrough of the Game </a><br></br>
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;

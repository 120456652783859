import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiFigma,
  SiAdobexd,
  SiVisualstudiocode,
  SiUnity,
  SiTrello,
  SiMiro,
  SiGithub,
  SiVercel,
  SiAdobephotoshop,
  SiAdobeillustrator,
  SiAdobepremierepro,
  SiAdobeaftereffects,
  SiAdobedreamweaver,
  SiAdobelightroom,
} from "react-icons/si";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={4} className="tool-icons">
        <SiFigma />
        <h2 className="skillCardText"> <strong>Figma</strong> </h2>
      </Col>
      <Col xs={4} md={4} className="tool-icons">
        <SiAdobexd />
        <h2 className="skillCardText"> <strong>Adobe XD</strong> </h2>
      </Col>
      <Col xs={4} md={4} className="tool-icons">
        <SiTrello />
        <h2 className="skillCardText"> <strong>Trello</strong> </h2>
      </Col>
      <Col xs={4} md={4} className="tool-icons">
        <SiMiro />
        <h2 className="skillCardText"> <strong>Miro</strong> </h2>
      </Col>
      <Col xs={4} md={4} className="tool-icons">
        <SiVisualstudiocode />
        <h2 className="skillCardText"> <strong>VSCode</strong> </h2>
      </Col>
      <Col xs={4} md={4} className="tool-icons">
        <SiUnity />
        <h2 className="skillCardText"> <strong>Unity</strong> </h2>
      </Col>
      <Col xs={4} md={4} className="tool-icons">
        <SiGithub />
        <h2 className="skillCardText"> <strong>GitHub</strong> </h2>
      </Col>
      <Col xs={4} md={4} className="tool-icons">
        <SiVercel />
        <h2 className="skillCardText"> <strong>Vercel</strong> </h2>
      </Col>
      <Col xs={4} md={4} className="tool-icons">
        <SiAdobephotoshop />
        <h2 className="skillCardText"> <strong>Photoshop</strong> </h2>
      </Col>
      <Col xs={4} md={4} className="tool-icons">
        <SiAdobeillustrator />
        <h2 className="skillCardText"> <strong>Illustrator</strong> </h2>
      </Col>
      <Col xs={4} md={4} className="tool-icons">
        <SiAdobepremierepro />
        <h2 className="skillCardText"> <strong>Premiere Pro</strong> </h2>
      </Col>
      <Col xs={4} md={4} className="tool-icons">
        <SiAdobeaftereffects />
        <h2 className="skillCardText"> <strong>After Effects</strong> </h2>
      </Col>
      {/* <Col xs={4} md={4} className="tool-icons">
        <SiAdobedreamweaver />
        <h2 className="skillCardText"> <strong>Dreamweaver</strong> </h2>
      </Col>
      <Col xs={4} md={4} className="tool-icons">
        <SiAdobelightroom />
        <h2 className="skillCardText"> <strong>Lightroom</strong> </h2>
      </Col> */}

    </Row>
  );
}

export default Toolstack;

import logo from "../Assets/logo.png";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  AiFillGithub,
  AiFillMail,
  AiFillLinkedin,
} from "react-icons/ai";
import { SiSpotify } from "react-icons/si";

function Footer() {
  // let date = new Date();
  // let year = date.getFullYear();
  return (
    <Container fluid className="footer">
      <Row>
        <Col md="6" className="footerLeft">
          <a href="/">
            <img src={logo} className="img-fluid logoFooter" alt="brand" />
          </a>
        </Col>
        <Col md="6" className="footer-body">
          <ul className="footer-icons">
            <li className="social-icons">
              <a
                href="https://github.com/Robin-Boe/"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <AiFillGithub size={30} className="footerIcons"/>
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://www.linkedin.com/in/robin-b%C3%B8e/"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <AiFillLinkedin size={30} className="footerIcons"/>
              </a>
            </li>
            {/* <li className="social-icons">
              <a
                href="https://open.spotify.com/user/applerobin"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <SiSpotify size={30} className="footerIcons"/>
              </a>
            </li> */}
            <li className="social-icons">
              <a
                href="mailto: robinboeinquiry@outlook.com"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <AiFillMail size={30} className="footerIcons"/>
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  MdArrowBack,
  MdOutlineViewQuilt,
} from "react-icons/md";
import { 
  SiFigma,
  SiAdobephotoshop,
} from "react-icons/si";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import persona1 from "../../Assets/Projects/mitisPersona1.png";
import persona2 from "../../Assets/Projects/mitisPersona2.png.png";
import persona3 from "../../Assets/Projects/mitisPersona3.png.png";
import wireframe from "../../Assets/Projects/wireframeMitis.png";
import highfi from "../../Assets/Projects/mitisHifi.png";

function Projects() {
  return (
    <Container fluid className="project-section-invidual">

      {/* Banner */}
      <Container fluid style={{paddingLeft: 20, paddingRight: 20}}>
        <Row className="portolioBannerMitis">
          <a href="/project" className="backArrow">
            <MdArrowBack className="backArrowIcon"/>
          </a>
          <h1 className="portolioHeader">
            <strong>Mitis </strong>
          </h1>
          <p className="portfolioSubtext">
            <MdOutlineViewQuilt/> UX Design 
          </p>
          <div className="d-flex justify-content-center">
                <a className="button-17" target="_blank" rel="noopener noreferrer" role="button" href={"https://www.figma.com/design/Yq1EAT97cEePTcP5n2oe4E/Hi-Fi-Prototype?node-id=0-1&t=iqWtFx0rH0EwbrZc-1"}> <SiFigma size={23}/> &nbsp;<b> View Prototype</b></a>
          </div>
        </Row>
      </Container>

      {/* Progress Bar */}
      <Container className="bar">
        <Row style={{paddingTop: 40}}>
          {/* Link for Pokemon Icons https://www.pokencyclopedia.info/en/index.php?id=sprites/spin-off/ico_shuffle */}
          <ProgressBar position percent={100} filledBackground="linear-gradient(to right, #C889E6, #d345af)">
            <Step transition="scale">
              {({ accomplished }) => (
                <a className="progressBarIcon" href={"#about"}><div className={`transitionStep ${accomplished ? "accomplished" : null}`}>
                  <img style={{ paddingTop: 30}} width="30" alt="step1" src="https://www.pokencyclopedia.info/sprites/spin-off/ico_shuffle/ico_shuffle_298.png"/>
                  <p style={{fontFamily: "Lato", fontWeight: 600}}> Overview </p>
                </div></a>
              )}
            </Step>
            <Step transition="scale">
              {({ accomplished }) => (
                <a className="progressBarIcon" href={"#research"}><div className={`transitionStep ${accomplished ? "accomplished" : null}`}>
                <img style={{ paddingTop: 30}} width="30" alt="step2" src="https://www.pokencyclopedia.info/sprites/spin-off/ico_shuffle/ico_shuffle_183.png"/>
                <p style={{fontFamily: "Lato", fontWeight: 600}}> User Research </p>
              </div></a>
              )}
            </Step>
            <Step transition="scale">
              {({ accomplished }) => (
                <a className="progressBarIcon" href={"#prototype"}><div className={`transitionStep ${accomplished ? "accomplished" : null}`}>
                <img style={{ paddingTop: 30}} width="30" alt="step3" src="https://www.pokencyclopedia.info/sprites/spin-off/ico_shuffle/ico_shuffle_184.png"/>
                <p style={{fontFamily: "Lato", fontWeight: 600}}> Prototype </p>
              </div></a>
              )}
            </Step>
          </ProgressBar>
        </Row>
      </Container>

      {/* Overview Text */}
      <Container style={{paddingTop: 60, paddingBottom: 30}}>
        <Row>
          <Col md={7}>
            <h1 className="blogHeader" id="about"> Project Overview </h1>
            <p className="blogText"> 
              Mitis is the app to simplify the process of both acquiring a gig, and booking a gig. It’s an app for both musicians and business alike. 
              It helps musicians keep up to date with gigs available, whilst also allowing businesses to create ads and discover musicians all in one app. 
              <br></br><br></br>
              This project was performed as part of a course at UIB, in a group of 3. The personas, wireframe  and high-fidelity prototype were all created by 
              myself, but planned and discussed as a group. The project had a duration of February 2021 to April 2021.
            </p>
          </Col>
          <Col md={5}>
            <h1 className="blogSubheader"> Components Used </h1>
            <p className="blogComponents"> 
              <SiFigma/> &nbsp; Figma <br></br>
              <SiAdobephotoshop/> &nbsp; Photoshop <br></br>
            </p>
          </Col>
        </Row>
          
        {/* Research Text */}
        <Row>
          <Col md={7}>
            <h1 className="blogHeader" id="research"> Research </h1>
            <h1 className="blogSubheader"> Personas </h1>
            <p className="blogText"> 
            Three personas were created to depict the users of the app. These personas represent users both from a business and musician perspective. 
            It considers their motivations, challenges and history, to understand their motives for potentially using Mitis. 
            These understandings are formulated in the user stories and user scenarios which are included within the personas. 

            NOTE: This project did not involve users in any aspect of the process!
            </p>
          </Col>
          <img alt="persona1" src={persona1} style={{borderRadius: 40}}></img>
          <p className="figureText"> Persona depicting a musician </p>

          <img alt="persona1" src={persona2} style={{borderRadius: 40}}></img>
          <p className="figureText"> Persona depicting a business owner </p>

          <img alt="persona1" src={persona3} style={{borderRadius: 40}}></img>
          <p className="figureText"> Persona depicting a musician </p>
          
        </Row>

        {/* Prototype Text */}
        <Row>
          <Col md={7}>
            <h1 className="blogHeader" id="prototype"> Prototype </h1>
            <h1 className="blogSubheader"> Low Fidelity Prototype (Wireframe) </h1>
            <p className="blogText"> 
            From the ideation and research, the initial designs were discussed and sketched. Initial sketches of the prototype’s layout were considered. 
            For instance, whether the ideal platform was an app or a website. Initial sketches were drawn, and some low-fidelity wireframes 
            were mocked-up from these initial sketches. Top top frames visuals the musicians perspective of finding gigs; with the bottom 
            frames being the business’ view
            </p>
          </Col>
          
          <img alt="persona1" src={wireframe} style={{borderRadius: 40}}></img>
          <p className="figureText"> Low fideilty digital wireframes</p>

          <Col md={7}>
            <h1 className="blogSubheader"> High Fidelity Prototype </h1>
            <p className="blogText"> 
            The high fidelity prototype evolves on the concept and visualization of the wireframes. 
            The core idea is still the same, however more details are included which would represent a more finalized product. 
            Some frames are included for visualization, however, to get a grasp of the full concept, please visit the &nbsp;
             <a className="linkInText" target="_blank" rel="noopener noreferrer" href="https://www.figma.com/design/Yq1EAT97cEePTcP5n2oe4E/Hi-Fi-Prototype?node-id=0-1&t=iqWtFx0rH0EwbrZc-1">Figma page! </a>
            </p>
          </Col>

          <img alt="persona1" src={highfi} style={{borderRadius: 40}}></img>
          <p className="figureText"> Selected frames from the high fidelity prototype</p>

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  MdArrowBack,
  MdOutlineViewQuilt,
} from "react-icons/md";
import { SiFigma} from "react-icons/si";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import lofi from "../../Assets/Projects/lucidityLofiMocku.png";
import mockup from "../../Assets/Projects/lucidityMockup.png";

function Projects() {
  return (
    <Container fluid className="project-section-invidual">

      {/* Banner */}
      <Container fluid style={{paddingLeft: 20, paddingRight: 20}}>
        <Row className="portolioBannerLucidity">
          <a href="/project" className="backArrow">
            <MdArrowBack className="backArrowIcon"/>
          </a>
          <h1 className="portolioHeader">
            <strong>lucidity </strong>
          </h1>
          <p className="portfolioSubtext">
            <MdOutlineViewQuilt/> UX/UI Design 
          </p>
          <div className="d-flex justify-content-center">
                <a className="button-17" target="_blank" rel="noopener noreferrer" role="button" href={"https://www.figma.com/design/qB2uTnjfbWm4joEt67plnY/lucidity?t=IppItIHVDaflwOFH-1"}> <SiFigma size={23}/> &nbsp;<b> View Prototype</b></a>
          </div>
        </Row>
      </Container>

      {/* Progress Bar */}
      <Container className="bar">
        <Row style={{paddingTop: 40}}>
          {/* Link for Pokemon Icons https://www.pokencyclopedia.info/en/index.php?id=sprites/spin-off/ico_shuffle */}
          <ProgressBar position percent={100} filledBackground="linear-gradient(to right, #C889E6, #d345af)">
            <Step transition="scale">
              {({ accomplished }) => (
                <a className="progressBarIcon" href={"#about"}><div className={`transitionStep ${accomplished ? "accomplished" : null}`}>
                  <img style={{ paddingTop: 30}} width="30" alt="step1" src="https://www.pokencyclopedia.info/sprites/spin-off/ico_shuffle/ico_shuffle_303.png"/>
                  <p style={{fontFamily: "Lato", fontWeight: 600}}> Overview </p>
                </div></a>
              )}
            </Step>
            <Step transition="scale">
              {({ accomplished }) => (
                <a className="progressBarIcon" href={"#prototype"}><div className={`transitionStep ${accomplished ? "accomplished" : null}`}>
                <img style={{ paddingTop: 30}} width="30" alt="step2" src="https://www.pokencyclopedia.info/sprites/spin-off/ico_shuffle/ico_shuffle_303-mega.png"/>
                <p style={{fontFamily: "Lato", fontWeight: 600}}> Prototype </p>
              </div></a>
              )}
            </Step>
          </ProgressBar>
        </Row>
      </Container>

      {/* Overview Text */}
      <Container style={{paddingTop: 60, paddingBottom: 30}}>
        <Row>
          <Col md={7}>
            <h1 className="blogHeader" id="about"> Project Overview </h1>
            <p className="blogText"> 
            With the ever growing artificial intelligence (AI) technology, the creations you can create expands every day. 
            However, to create these creations, you need to write detailed prompts identifying every detail. 
            Precisely here is where lucidity is here to help users of any age create their unique creations. 
            lucidity is an AI simplifier, which simplifies and creates prompts for you, to create your own unique images and music
            </p>
          </Col>
          <Col md={5}>
            <h1 className="blogSubheader"> Components Used </h1>
            <p className="blogComponents"> 
              <SiFigma/> &nbsp; Figma <br></br>
            </p>
          </Col>
        </Row>

        {/* Prototype Text */}
        <Row>
          <Col md={7}>
            <h1 className="blogHeader" id="prototype"> Prototype </h1>
            <h1 className="blogSubheader"> Low Fidelity Prototype </h1>
            <p className="blogText"> 
            The low fidelity prototype is a simple wireframe depicting the main features required for the high fidelity prototype. 
            It’s basic in nature, to follow a specific blueprint for the high fidelity prototype. 
            All frames are included in the following &nbsp;
            <a className="linkInText" target="_blank" rel="noopener noreferrer" href="https://www.figma.com/design/qB2uTnjfbWm4joEt67plnY/lucidity?t=IppItIHVDaflwOFH-1">Figma</a>
            &nbsp;link, which also includes the high fidelity prototype.
            </p>
          </Col>

          <img alt="persona1" src={lofi} style={{borderRadius: 10}}></img>
          <p className="figureText"> Selected frames from the low fidelity prototype</p>
          
          <h1 className="blogSubheader"> High Fidelity Prototype </h1>
            <p className="blogText"> 
            The high fidelity prototype captures the main features of the wireframe, and adds more details, whilst expanding upon the concept. 
            For instance, including pages such as FAQ (frequently asked questions) and library. Currently, 
            the music creation except for the design is completed, with the image creation still being developed. 
            The prototype is fully functional, and you can click through, with the following &nbsp;
            <a className="linkInText" target="_blank" rel="noopener noreferrer" href="https://www.figma.com/proto/qB2uTnjfbWm4joEt67plnY/lucidity?node-id=5-483&t=YlJmM0cVIU3DiJDN-1&scaling=min-zoom&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=5%3A483">link.</a>  
            </p>

          <img alt="persona1" src={mockup} style={{borderRadius: 40}}></img>
          <p className="figureText"> Selected frames from the high fidelity prototype</p>

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;

import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Container, Row, Col } from "react-bootstrap";
import logo from "../Assets/logo.png";
import { Link } from "react-router-dom";
import {
  AiOutlineLayout,
  AiFillLinkedin,
  AiFillGithub,
  AiFillMail,
} from "react-icons/ai";
import { SiSpotify } from "react-icons/si";
import {
  TiHomeOutline,
  TiUserOutline,
} from "react-icons/ti";
import { useLocation } from 'react-router-dom';

function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);
  const {pathname} = useLocation();

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}
    >
      <Container fluid>

        {/* Mobile Logo */}
        <Navbar.Brand href="/" className="mobileLogo">
          <img src={logo} className="img-fluid logo" alt="brand" />
        </Navbar.Brand>

        {/* Mobile Hamburger Menu */}
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>

        {/* Navbar */}
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="" defaultActiveKey="#home">
            <Nav.Item>
              <Nav.Link className={(pathname === '/') ? 'active' : ''} as={Link} to="/" onClick={() => updateExpanded(false)}>
                <TiHomeOutline style={{ marginBottom: "2px" }} /> Home
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className={(pathname === '/project') ? 'active' : ''} as={Link} to="/project" onClick={() => updateExpanded(false)}>
                <AiOutlineLayout style={{ marginBottom: "2px" }} /> Portfolio
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className={(pathname === '/about') ? 'active' : ''} as={Link} to="/about" onClick={() => updateExpanded(false)}>
                <TiUserOutline size={25} style={{ marginBottom: "2px" }} /> About
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>

        {/* Logo */}
        <Navbar.Brand href="/" className="logoComp">
          <img src={logo} className="logo" alt="brand" />
        </Navbar.Brand>

        {/* Social Media Icons */}
        <Row>
          <Col md="12" className="footer-body">
          <ul className="navbar-icons">
            <li className="social-icons">
              <a
                href="https://github.com/Robin-Boe/"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <AiFillGithub size={27} className="footerIcons"/>
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://www.linkedin.com/in/robin-b%C3%B8e/"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <AiFillLinkedin size={27} className="footerIcons"/>
              </a>
            </li>
            {/* <li className="social-icons">
              <a
                href="https://open.spotify.com/user/applerobin"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <SiSpotify size={27} className="footerIcons"/>
              </a>
            </li> */}
            <li className="social-icons">
              <a
                href="mailto: robinboeinquiry@outlook.com"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <AiFillMail size={27} className="footerIcons"/>
              </a>
            </li>
          </ul>
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
}

export default NavBar;

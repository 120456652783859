import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  DiJavascript1,
  DiReact,
  // DiNodejs,
  DiHtml5,
  // DiLinux,
  DiPython,
} from "react-icons/di";
import {
  MdOutlineViewQuilt,
  MdOutlinePersonSearch,
  MdOutlineAnalytics,
  MdOutlinePinch,
} from "react-icons/md";
import { 
  FaVrCardboard,
  FaGamepad,  
} from "react-icons/fa";
import {SiCsharp} from "react-icons/si";
import { BsBootstrapFill } from "react-icons/bs";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={4} className="skill-icons">
        <MdOutlineViewQuilt />
        <h2 className="skillCardText"> <strong>UX Design</strong> </h2>
      </Col>
      <Col xs={4} md={4} className="skill-icons">
        <MdOutlinePersonSearch />
        <h2 className="skillCardText"> <strong>User Research</strong> </h2>
      </Col>
      <Col xs={4} md={4} className="skill-icons">
        <DiPython />
        <h2 className="skillCardText"> <strong>Python</strong> </h2>
      </Col>
      <Col xs={4} md={4} className="skill-icons">
        <SiCsharp/>
        <h2 className="skillCardText"> <strong>C#</strong> </h2>
      </Col>
      <Col xs={4} md={4} className="skill-icons">
        <FaVrCardboard />
        <h2 className="skillCardText"> <strong>VR Development</strong> </h2>
      </Col>
      <Col xs={4} md={4} className="skill-icons">
        <FaGamepad/>
        <h2 className="skillCardText"> <strong>Game Design</strong> </h2>
      </Col>
      <Col xs={4} md={4} className="skill-icons">
        <DiHtml5 />
        <h2 className="skillCardText"> <strong>HTML5</strong> </h2>
      </Col>
      <Col xs={4} md={4} className="skill-icons">
        <BsBootstrapFill />
        <h2 className="skillCardText"> <strong>Bootstrap</strong> </h2>
      </Col>
      <Col xs={4} md={4} className="skill-icons">
        <DiJavascript1 />
        <h2 className="skillCardText"> <strong>Javascript</strong> </h2>
      </Col>
      <Col xs={4} md={4} className="skill-icons">
        <DiReact />
        <h2 className="skillCardText"> <strong>React</strong> </h2>
      </Col>
      {/* <Col xs={4} md={4} className="skill-icons">
        <DiNodejs />
        <h2 className="skillCardText"> <strong>Node.js</strong> </h2>
      </Col>
      <Col xs={4} md={4} className="skill-icons">
        <DiLinux/>
        <h2 className="skillCardText"> <strong>Linux</strong> </h2>
      </Col> */}
      <Col xs={4} md={4} className="skill-icons">
        <MdOutlinePinch />
        <h2 className="skillCardText"> <strong>User Testing</strong> </h2>
      </Col>
      <Col xs={4} md={4} className="skill-icons">
        <MdOutlineAnalytics />
        <h2 className="skillCardText"> <strong>Data Analysis</strong> </h2>
      </Col>
    </Row>
  );
}

export default Techstack;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Home2 from "./Home2";
import { AiOutlineDownload } from "react-icons/ai";
import { AiOutlineLayout } from "react-icons/ai";
import pdf from "../../Assets/../Assets/RobinBoe_CV.pdf";
import Type from "./Type";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Container className="home-content">
          <Row>
            <Col md={13} className="home-header">
              {/* Typewriter Effect */}
              <div style={{ position: "relative", paddingTop: 50, paddingBottom: 50}}>
                <Type />
              </div>

              {/* CV and Project Buttons */}
              <div className="d-flex justify-content-center">
                <a className="button-17" role="button" href={pdf}> <AiOutlineDownload size={23}/> &nbsp;<b>Download CV</b></a>
                <a className="button-18" role="button" href="/project"> <AiOutlineLayout size={21}/> &nbsp;<b>View Projects</b></a>
              </div>     
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;

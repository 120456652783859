import React from "react";
import Card from "react-bootstrap/Card";
import { Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { IoTimeOutline } from "react-icons/io5";
import { MdOutlineDesignServices } from "react-icons/md";
import { AiOutlineTool } from "react-icons/ai";
import { MdDevices } from "react-icons/md";
import { BsGithub } from "react-icons/bs";
import {SiFigma} from "react-icons/si";

function ProjectCards(props) {
  return (
    <Card className="project-card-view">
      <a href={props.viewLink}>
      <Row>
        <Col>
          <Card.Body className="cardLeft">
            <Card.Title style={{ fontSize: "25px", fontFamily: "Lato", fontWeight: "600"}}>{props.title}</Card.Title>
            <Card.Text style={{ textAlign: "justify", fontSize: "17px", fontFamily: "Lato", fontWeight: "300" }}>
              {props.description}
            </Card.Text>

            <IoTimeOutline color="#C889E6" size={20}/> {props.date} <br></br>
            <MdOutlineDesignServices color="#C889E6" size={20}/> {props.design} <br></br>
            <AiOutlineTool color="#C889E6" size={20}/> {props.platform} <br></br>
            <br></br>

            {/* Buttons */}
            <Button variant="secondary" href={props.viewLink}>
                <MdDevices /> &nbsp;
                {"View Project"}
            </Button>

            {/* Space Between Buttons */}
            {"\n"}{"\n"}{"\n"}{"\n"}

            <Button variant="primary" href={props.link} target="_blank" style={{ marginLeft: "3px" }}>
              {props.isFigma ? <SiFigma/> : <BsGithub/>} &nbsp;
              {props.isFigma ? "Figma" : "GitHub"}
            </Button>
          
          </Card.Body>
        </Col>
        <Col md={4}>
          <Card.Img variant="right" src={props.imgPath} alt="card-img" />
        </Col>
      </Row>
      </a>
    </Card>
  );
}
export default ProjectCards;
